
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import SimulationDataService from '../../services/SimulationDataService';
import FormButton from '../../components/forms/Button.vue';
import FormInput from '../../components/forms/Input.vue';
import FormChoice from '../../components/forms/Choice.vue';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';
import FinanceTypeDataHelper from '../../helpers/FinanceTypeDataHelper';
import ProductDataHelper from '../../helpers/ProductDataHelper';
import SimulationDataHelper from '../../helpers/SimulationDataHelper';
import UndefinedResourceError from '../../types/exceptions/UndefinedResourceError';

export default defineComponent({
  name: 'Simulation',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormInput,
    FormChoice,
  },
  data() {
    return {
      form: {
        validations: {},
        labels: {},
        styles: {},
        inputs: {} as {[key: string]: any},
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      /* GET OBJECTS FROM STORE */
      vm.$store.dispatch('setSelectedSegment', SegmentDataHelper.getObjectFromStore(vm.$store, vm.$route.params.segment as string));
      vm.$store.dispatch('setSelectedFinanceType', FinanceTypeDataHelper.getObjectFromStore(vm.$store, vm.$route.params.financeType as string));
      vm.$store.dispatch('setSelectedProduct', ProductDataHelper.getObjectFromStore(vm.$store, vm.$route.params.product as string));

      if (Object.keys(vm.$store.state.selected.segment).length === 0 || Object.keys(vm.$store.state.selected.financeType).length === 0 || Object.keys(vm.$store.state.selected.product).length === 0) {
        throw new UndefinedResourceError();
      }

      vm.form.validations = data.validations;
      vm.form.labels = data.labels;
      vm.form.styles = data.styles;

      vm.init();

      Object.keys(vm.form.validations).forEach((key) => {
        vm.form.inputs[key] = {
          value: null,
          error: null,
        } as {[key: string]: any};
      });
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SimulationDataHelper.prepareComponentData(
        this.$store,
        this.$route.params.segment as string,
        this.$route.params.financeType as string,
        this.$route.params.product as string,
        callback,
      );
    },
    defaultValue(name: string) {
      const val = localStorage.getItem(name);

      if (val === null || val === undefined) return null;

      if (['is_customer', 'is_saver', 'other_credit'].includes(name)) return val;

      return Number(val);
    },
    addition() {
      this.form.inputs.total_income.value = +this.form.inputs.customer_income.value + +this.form.inputs.customer_co_income.value;
    },
    getInputs() {
      const inputs = {} as any;

      Object.entries(this.form.inputs).forEach((key: any, element: any) => {
        inputs[key[0]] = this.form.inputs[key[0]].value;
        if (inputs[key[0]] !== null) localStorage.setItem(key[0], inputs[key[0]]);
      });

      return inputs;
    },
    validateForm() {
      let error = false;

      const inputs = document.querySelectorAll('#simulation-form input');
      for (let i = 0; i < inputs.length; i += 1) {
        const el: any = inputs[i];
        el.click();
        el.focus();
        el.blur();
      }

      Object.entries(this.form.inputs).forEach((key: any, element: any) => {
        if (this.form.inputs[key[0]].error !== null) {
          error = true;
          return false;
        }

        return true;
      });

      return error;
    },
    setSimulationInStore(event: any) {
      event.preventDefault();
      const vm = this;

      // Validate form
      const error = this.validateForm();

      if (!error) {
        this.$store.dispatch('isLoading', true);

        // Load recaptcha token
        this.recaptcha(this)
          .then((resp: any) => {
            const data = this.getInputs();
            data.recaptcha = vm.$store.state.recaptcha;

            SimulationDataService.execute(this.$store.state.selected.segment, this.$store.state.selected.product, data)
              .then((response: any) => {
                /* FLAG STEP AS COMPLETED */
                this.setStepAsCompleted(this, true);
              })
              .catch((e: any) => {
                this.$store.dispatch('isLoading', false);

                if (e.response.status === 422) {
                  Object.keys(e.response.data.data.errors).forEach((name) => {
                    [vm.form.inputs[name].error] = e.response.data.data.errors[name];
                  });
                }
              });
          });
      }
    },
  },
  computed: {
    total_income_calcul: {
      get(): number {
        if (!Object.keys(this.form.inputs).length) return 0;

        const income = !Number.isNaN(this.form.inputs.customer_income.value) ? Number(this.form.inputs.customer_income.value) : 0;
        const CoIncome = !Number.isNaN(this.form.inputs.customer_co_income.value) ? Number(this.form.inputs.customer_co_income.value) : 0;
        return income + CoIncome;
      },
      set(value: any) {
        if (Object.keys(this.form.inputs).length) {
          this.form.inputs.total_income.value = value;
        }
      },
    },
    is_customer: {
      get(): string {
        return this.form.inputs.is_customer.value;
      },
      set(value: string) {
        this.form.inputs.is_customer.value = value;

        if (this.form.inputs.is_customer.value === 'no') {
          this.form.inputs.is_saver.value = null;
        }
      },
    },
    other_credit: {
      get(): string {
        return this.form.inputs.other_credit.value;
      },
      set(value: any) {
        this.form.inputs.other_credit.value = value;

        if (this.form.inputs.other_credit.value === 'no') {
          this.form.inputs.other_credit_charge.value = null;
        }
      },
    },
  },
  watch: {
    total_income_calcul(value, oldValue) {
      if (Object.keys(this.form.inputs).length) {
        this.form.inputs.total_income.value = value;
      }
    },
  },
});
