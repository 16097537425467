import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-493631d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex flex-row space-x-10" }
const _hoisted_4 = ["onClick", "textContent"]
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.name,
    class: _normalizeClass(_ctx.validationClasses)
  }, [
    _createElementVNode("input", {
      type: "hidden",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validate()))
    }),
    _createElementVNode("div", {
      class: "text-gray-400 text-sm 2xl:text-base mb-3",
      innerHTML: _ctx.formattedPlaceholder
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex-grow",
          key: option.value
        }, [
          _createElementVNode("button", {
            onClick: ($event: any) => {_ctx.updateChoice(option.value, $event);},
            class: _normalizeClass([option.value === _ctx.value ? 'selected-choice ' : '', "block border bg-white border-gray-300 rounded-lg text-gray-400 focus-within:border-primary py-2 2xl:py-3 px-4 w-full text-sm 2xl:text-base appearance-none focus:outline-none bg-transparent"]),
            textContent: _toDisplayString(option.label)
          }, null, 10, _hoisted_4)
        ]))
      }), 128))
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "error text-red-600 font-normal text-xs mt-0",
          textContent: _toDisplayString(_ctx.error)
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}